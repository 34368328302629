





import Vue from "vue";
export default Vue.extend({
  name: "CharacterLength",
  props: {
    value: {
      default: "",
      type: String,
    },
  },
});
